import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './text.css';
import { css, compose, useStyleProps, textStyleProps } from '..';

import type { HTMLProps, SlotProps, TextStyleProps } from '..';
import type { PropsWithChildren } from 'react';

import type { TextVariants } from './text.css';

export type TextProps = PropsWithChildren<HTMLProps<HTMLSpanElement> & TextVariants & SlotProps & TextStyleProps>;

/**
 * @name
 * Text
 *
 * @description
 * Non semantic UI text element that inherits styling from it's
 * parent container.
 *
 * @example
 * <Text asChild>
 *   <p>Hello, GoodHuman!</p>
 * </Text>
 */
export const Text = forwardRef<HTMLElement, TextProps>(function Text(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props, textStyleProps);
  const {
    asChild,
    children,
    color: _color,
    size = 'small',
    emphasis,
    capitalize,
    uppercase,
    lowercase,
    underline,
    throughline,
  } = props; // Pick HTML color for typing
  const ElementType = asChild ? Slot : 'span';

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={compose(
        css(styles.reset, styles.text, styleProps),
        styles.variants({ size, emphasis, uppercase, capitalize, lowercase, underline, throughline }),
      )}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
