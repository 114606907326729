import React from 'react';
import { Icon } from '../icon';

import type { IconProps } from '../icon';

export function ChevronDown(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={15}
        d="M56 88 l 72 80 l 72 -80"
      />
    </Icon>
  );
}
