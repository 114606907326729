import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef, SVGAttributes } from 'react';

import { IconContext } from './icon-provider';
import { css, compose, iconStyleProps, useContextProps, useStyleProps } from '..';

import type { HTMLProps, IconStyleProps } from '..';
import { PropsWithChildren } from 'react';
import { IconVariants, iconVariants } from './icon.css';

export type IconProps = PropsWithChildren<
  HTMLProps<SVGElement> & IconStyleProps & IconVariants & SVGAttributes<SVGElement>
>;

export const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(props, ref) {
  const { children, viewBox = '0 0 256 256', size, ...contextProps } = useContextProps(IconContext, props);
  const { styleProps, ...otherProps } = useStyleProps(contextProps, iconStyleProps);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="transparent"
      viewBox={viewBox}
      {...filterDOMProps(otherProps)}
      className={compose(iconVariants({ size }), css(styleProps))}
      ref={ref}
    >
      {children}
    </svg>
  );
});
