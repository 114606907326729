import { useFocusWithin, useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import type { PropsWithChildren } from 'react';
import React, { useRef, useState } from 'react';
import { IValidations } from '../types';
import { useMultiValidation } from '../utils';
import { compose, css } from '..';
import { InputGroupProvider, InputGroupProviderProps } from './input-group-providers';
import * as styles from './input-group.css';

export type InputGroupProps = PropsWithChildren<{
  /**
   * Whether inputs inside have divider or outline between
   */
  combineInput?: boolean;

  /**
   * Status of validation
   */
  validations?: IValidations;

  /**
   * Whether show disabled status
   */
  isDisabled?: boolean;
}>;

/**
 * @name
 * InputGroup
 *
 * @description
 * Non semantic UI text element that inherits styling from it's
 * parent container.
 *
 * @example
 * <InputGroup asChild>
 *   <Select>
 *       {options.map((item) => (
 *          <Select.Item key={item.id}>{item.name}</Select.Item>
 *        ))}
 *   </Select>
 *   <Input/>
 * </InputGroup>
 */
export const InputGroup = (props: InputGroupProps) => {
  const { children, validations, combineInput = false, isDisabled } = props; // Pick HTML color for typing
  const ref = useRef<HTMLDivElement>(null);
  const [isFocus, setIsFocus] = useState(false);

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange(isFocusWithin) {
      setIsFocus(isFocusWithin);
    },
  });
  const { hoverProps, isHovered } = useHover({});

  const wrapperProps = mergeProps(hoverProps, focusWithinProps);

  const overrideProps: InputGroupProviderProps = {
    borderRadius: 'inherit',
    borderStyle: combineInput ? 'none' : '',
    boxShadow: combineInput ? 'none !important' : '',
    isInsideGroupInput: combineInput,
    isDisabled: isDisabled,
  };

  const { validationFields, isInvalid } = useMultiValidation({ validations });

  return (
    <InputGroupProvider {...overrideProps}>
      <div
        ref={ref}
        {...wrapperProps}
        className={compose(
          css(styles.inputGroup),
          styles.inputGroupVariants({
            combineInput,
            isFocus: combineInput && isFocus,
            isHover: combineInput && isHovered,
            isErrored: isInvalid,
            isDisabled,
          }),
        )}
      >
        {children}
      </div>
      {validationFields()}
    </InputGroupProvider>
  );
};
