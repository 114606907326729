import React, { DOMAttributes, forwardRef } from 'react';
import { LabelAriaProps } from '@react-aria/label';
import { filterDOMProps } from '@react-aria/utils';
import { FocusableElement } from '@react-types/shared';
import { isMobile } from 'react-device-detect';

import { TooltipIcon } from 'registration-flow-v2/icon';
import { Tooltip } from 'registration-flow-v2/tool-tip';
import * as styles from './label.css';
import { css, useStyleProps } from '..';

import type { SlotProps, TextStyleProps } from '..';
import type { PropsWithChildren } from 'react';

export type LabelProps = PropsWithChildren<
  React.LabelHTMLAttributes<HTMLLabelElement> &
    LabelAriaProps &
    SlotProps &
    TextStyleProps & {
      /**
       * Whether show optional indicator
       */
      isRequired?: boolean;

      /**
       * Right side of label
       */
      labelRightSide?: React.ReactNode;

      /**
       * Description of label
       */
      description?: React.ReactNode;

      /**
       * Description props for aria
       */
      descriptionProps?: DOMAttributes<FocusableElement>;

      /**
       * Tooltip icon
       */
      tooltip?: React.ReactNode;
    }
>;

export const Label = forwardRef<HTMLLabelElement, LabelProps>(function Label(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props);
  const { children, isRequired = true, labelRightSide, description, descriptionProps, tooltip } = props;

  return (
    <div className={css(styles.reset, styles.base, styleProps)()}>
      <div>
        <label
          {...filterDOMProps(otherProps)}
          htmlFor={props.htmlFor}
          ref={ref}
          className={css(styles.reset, styles.label)()}
        >
          {children}
          {!isRequired && <Optional />}
          {tooltip && (
            <Tooltip content={tooltip} delay={0} height={isMobile ? 18 : 16} isTooltipForLabel>
              <TooltipIcon color="$neutralDark1" width={16} height={16} />
            </Tooltip>
          )}
        </label>
        {description && (
          <div {...descriptionProps} className={css(styles.description)()}>
            {description}
          </div>
        )}
      </div>
      {labelRightSide}
    </div>
  );
});

function Optional() {
  return <span className={css(styles.reset, styles.optional)()}>(optional)</span>;
}
