import {
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { IRegistrationAccountInfo, IPendingPrimaryOwner } from 'interfaces/auth-store-types';
import firebaseApp from 'stores/firebase-app';
import apiClient from 'utilities/api-client';
import { EmailAlreadyExistType } from 'utilities/enum-utils';

const initialState = {
  currentUser: null,
  isAppLoaded: false,
  portalUser: null,
  portalUserList: null,
  invitation: null,
  isUserBlocked: false,
  isWorkerRemoved: false,
  pendingPrimaryOwnerFirebaseUid: '',
};

const authStore = {
  state: initialState,
  reducers: {
    setCurrentUser: (state, payload) => ({ ...state, currentUser: payload }),
    setAppLoaded: (state, payload) => ({ ...state, isAppLoaded: payload }),
    setPortalUserList: (state, payload) => ({ ...state, portalUserList: payload }),
    setCurrentPortalUser: (state, payload) => ({ ...state, portalUser: payload }),
    setInvitation: (state, payload) => ({ ...state, invitation: payload }),
    setIsUserBlocked: (state, payload) => ({ ...state, isUserBlocked: payload }),
    setIsWorkerRemoved: (state, payload) => ({ ...state, isWorkerRemoved: payload }),
    setFirebaseUid: (state, payload) => ({ ...state, pendingPrimaryOwnerFirebaseUid: payload }),
  },
  effects: (dispatch) => ({
    async doSignIn(payload, state) {
      const auth = firebaseApp.auth;
      const { login, password } = payload;
      try {
        const results = await signInWithEmailAndPassword(auth, login, password);

        const { displayName, email, emailVerified, isAnonymous, metadata, phoneNumber, photoURL, uid } = results.user;

        const currentUser = {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          uid,
        };

        return currentUser;
      } catch (error) {
        throw Error('Invalid email or password, please try again.');
      }
    },

    async doRegister(payload, state) {
      const auth = firebaseApp.auth;
      const { email, password, firstName, lastName, token, serviceProviderId, mobile } = payload;

      try {
        const results = await createUserWithEmailAndPassword(auth, email, password);

        const { emailVerified, isAnonymous, metadata, phoneNumber, photoURL, uid } = results.user;

        const mobileCountryCode = 'AU';

        const dbUser = {
          serviceProviderId,
          firstName,
          lastName,
          email,
          token,
          mobile,
          mobileCountryCode,
        };

        await apiClient.post('/api/portal/service-provider/portal-user/new', dbUser);

        const currentUser = {
          displayName: firstName + ' ' + lastName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          uid,
        };

        return currentUser;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCreateNewPrimaryOwner(payload: {
      firstName: string;
      lastName: string;
      password: string;
      email: string;
    }): Promise<boolean> {
      const auth = firebaseApp.auth;
      const { email, password, firstName, lastName } = payload;

      try {
        const { user } = await createUserWithEmailAndPassword(auth, email, password);
        const { emailVerified, uid } = user;

        const dbUser = {
          firstName,
          lastName,
          emailVerified,
          email,
          isAnonymous: true,
          firebaseUid: uid,
        };

        const response = await apiClient.post('/createNewPendingPrimaryOwner', dbUser);

        dispatch.authStore.setFirebaseUid(response.data.pendingPrimaryOwnerFirebaseUid);

        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doGetPendingPrimaryOwner(payload: { firebaseUid: string }): Promise<IPendingPrimaryOwner> {
      const { firebaseUid } = payload;

      try {
        const result = await apiClient.get(`/getPendingPrimaryOwner/${firebaseUid}`);

        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doGetAlreadyExistingPortalUserType(payload: {
      email: string;
    }): Promise<{ isAlreadyLinked: boolean; isWaitingToCreateOrganisation: boolean }> {
      try {
        const result = await apiClient.post('/getExistingUserType', payload);

        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doResendNewPrimaryOwnerEmail(payload: { email: string }): Promise<boolean> {
      try {
        await apiClient.post('/resendNewPendingPrimaryOwnerEmail', payload);

        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doLogIn(payload: { email: string; password: string }): Promise<IRegistrationAccountInfo> {
      const auth = firebaseApp.auth;
      const { email, password } = payload;
      try {
        let uid = null;
        const userTypeResults = await apiClient.post('/getExistingUserType', payload);
        if (userTypeResults?.data?.isWaitingToCreateOrganisation) {
          const results = await signInWithEmailAndPassword(auth, email, password);
          uid = results?.user.uid;
        }

        return { userType: userTypeResults.data, uid };
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCheckExistingEmailDomain(payload: { email: string }): Promise<{
      emailDomainExist: boolean;
      targetEmail: string | null;
      serviceProviderName: string | null;
      isNewUser: boolean;
      emailAlreadyExistType: EmailAlreadyExistType;
    }> {
      try {
        const results = await apiClient.post('/checkExistingEmailDomain', payload);
        return results.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCheckABNNumber(payload: { abn: string }): Promise<{
      mainName: {
        organizationName: string;
      };
      entityStatus: {
        entityStatusCode: string;
        effectiveFrom: string;
        effectiveTo: string;
      };
      entityType: {
        entityDescription: string;
      };
      goodsAndServicesTax: {
        effectiveFrom: string;
        effectiveTo: string;
      };
      mainBusinessPhysicalAddress: {
        stateCode: string;
        postcode: string;
      };
    }> {
      try {
        const results = await apiClient.get(`/business-registration-number/${payload.abn}`);
        return results.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRequestAccess(payload: {
      requesterFirstName: string;
      requesterLastName: string;
      requesterEmail: string;
      targetEmail: string;
      serviceProviderName: string;
      message: string;
    }): Promise<boolean> {
      try {
        const results = await apiClient.post('/requestAccessToInstanceByMail', payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCheckValidateToken(payload, state) {
      try {
        const { token } = payload;
        const result = await apiClient.get(`/verify/service-provider/${token}`);
        dispatch.authStore.setInvitation(result.data);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doGetLoginToken(payload, state) {
      const { type } = payload;
      try {
        let fullUrl = `/login`;
        if (type === 'zendesk') {
          fullUrl += '?type=zendesk';
        }

        const result = await apiClient.get(fullUrl);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doSendResetPasswordEmail(payload, state) {
      try {
        const { email } = payload;
        await apiClient.post('/reset-password', { email });
      } catch (e) {
        throw e;
      }
    },

    async doCheckActionToken(payload, state) {
      try {
        const { code } = payload;
        return await checkActionCode(firebaseApp.auth, code);
      } catch (e) {
        throw e;
      }
    },

    async doSetNewPassword(payload, state) {
      try {
        const { code, password } = payload;
        return await confirmPasswordReset(firebaseApp.auth, code, password);
      } catch (e) {
        throw e;
      }
    },

    async doSignOutUsers() {
      await firebaseApp.auth.signOut();
    },

    async doCreateServiceProviderInstance(payload: {
      serviceProviderName: string;
      serviceProviderPhone: string;
      email: string;
      timezone: string;
      abn: string;
      uid: string;
      stripeCustomerId: string;
      stripeSubscriptionId: string;
      ndisNumber: string;
      companySize: string;
    }): Promise<{
      serviceProviderId: string;
      token: string;
    }> {
      const result = await apiClient.post(`/createNewServiceProvider`, payload);
      return result.data;
    },

    async doReEnterNewPrimaryOwnerEmail(payload: {
      email: string;
      pendingPrimaryOwnerFirebaseUid: string;
    }): Promise<boolean> {
      try {
        await apiClient.post('/reEnterNewPendingPrimaryOwnerEmail', payload);

        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  }),
};

export default authStore;
