import React, { PropsWithChildren, useEffect } from 'react';
import { useTooltipTriggerState, TooltipTriggerProps } from '@react-stately/tooltip';
import { useTooltipTrigger, useTooltip } from '@react-aria/tooltip';
import { useOverlayPosition } from '@react-aria/overlays';
import { Placement } from '@react-types/overlays';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';

import * as styles from './tooltip.css';
import { BaseStyleProps, compose, css, useStyleProps } from '..';

export type TooltipProps = PropsWithChildren<
  BaseStyleProps &
    TooltipTriggerProps & {
      /**
       * The content shown in the tooltip
       */
      content: React.ReactNode;

      /**
       * Whether placement of tooltip
       */
      placement?: Placement;

      /**
       * Whether tooltip of label
       */
      isTooltipForLabel?: boolean;
    }
>;

const Tooltip = (props: TooltipProps) => {
  const { placement = 'top left', content, children, trigger, isTooltipForLabel } = props;

  const ref = React.useRef<HTMLDivElement>();
  const overlayRef = React.useRef<HTMLDivElement>();
  const state = useTooltipTriggerState(props);
  const { placement: placementOverlay } = useOverlayPosition({
    targetRef: ref,
    overlayRef,
    placement,
    isOpen: state.isOpen,
  });

  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);
  const { tooltipProps: tooltipContentProps } = useTooltip(tooltipProps, state);
  const { styleProps } = useStyleProps(props);
  const isClickableTooltip = trigger === 'focus' || isMobile || isTablet;

  const getPlacement = (availablePlacement: string, placement: string) => {
    if (availablePlacement === placement) return placement;

    const axis = availablePlacement;
    const splitPlacement = placement?.split(' ')[1];

    if (axis) return `${axis}${splitPlacement ? '-' + splitPlacement : ''}`.trim();

    return placement;
  };

  const openTooltip = (e) => {
    e.preventDefault();

    isClickableTooltip && state.open();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target) && isClickableTooltip) state.close();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [overlayRef]);

  const getOverlayMobileStyles = () => {
    if (isMobileOnly && isTooltipForLabel) {
      const rect = ref?.current?.getBoundingClientRect();
      const offsetLeft = rect?.left;
      const left = '-' + offsetLeft + 8; // 8 is a half of tooltip icon

      return {
        left: left + 'px', // fix left position of tooltip content
        arrowLeft: (Number(left) + 8 + 8) * -1 + 'px', // adjust arrow at center tooltip icon
        marginLeft: '16px', // fix margin left of tooltip === padding left at screen width = 390px as design
      };
    }
    return undefined;
  };

  return (
    <div className={css(styles.tooltip, styleProps)()}>
      <div ref={ref} {...triggerProps} onClick={openTooltip}>
        {children}
      </div>

      {state.isOpen && (
        <div
          ref={overlayRef}
          className={compose(
            css(styles.tooltipContent),
            styles.tooltipContentVariants({
              placement: getPlacement(placementOverlay, placement) as any,
            }),
          )}
          style={getOverlayMobileStyles()}
          {...tooltipContentProps}
        >
          <span
            className={compose(
              css(styles.arrowTooltip),
              styles.arrowTooltipVariants({ placement: getPlacement(placementOverlay, placement) as any }),
            )}
            style={{ left: getOverlayMobileStyles()?.arrowLeft }}
          />
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
