import React, { RefObject, useRef } from 'react';

import { FocusScope } from '@react-aria/focus';
import { AriaOverlayProps, DismissButton, useOverlay } from '@react-aria/overlays';
import { filterDOMProps } from '@react-aria/utils';
import { AriaDialogProps } from '@react-types/dialog';
import type { PropsWithChildren } from 'react';
import type { BoxStyleProps, HTMLProps } from '..';
import { boxStyleProps, compose, css, useStyleProps } from '..';
import * as styles from './popover.css';

export type PopoverProps = PropsWithChildren<
  AriaOverlayProps &
    AriaDialogProps &
    BoxStyleProps &
    HTMLProps<HTMLDivElement> & {
      /**
       * Popover ref
       */
      popoverRef?: RefObject<HTMLDivElement>;
    }
>;

/**
 * @name
 * Popover
 *
 * @description
 *  Popover is used to create dropdown
 *
 * @example
 * Use list `ListBox`
 * <Popover isOpen={isOpen} onClose={closeHandler}>
 *    <ListBox {...listboxProps} />
 * </Popover>
 *
 */
export const Popover = (props: PopoverProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { popoverRef = ref, isOpen, children, shouldCloseOnBlur, isKeyboardDismissDisabled, onClose } = props;

  // Handle events that should cause the popup to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur,
      isKeyboardDismissDisabled,
      isDismissable: true,
    },
    popoverRef,
  );

  const { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);

  if (!isOpen) {
    return null;
  }

  // Add a hidden <DismissButton> component at the end of the popover
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus contain autoFocus>
      <div
        {...overlayProps}
        {...filterDOMProps(otherProps)}
        ref={popoverRef}
        className={compose(css(styles.popover), css(styleProps))}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  );
};
