import { AriaFieldProps, useField } from '@react-aria/label';
import type { PropsWithChildren, ReactNode } from 'react';
import React, { useRef } from 'react';
import type { HTMLProps } from '..';
import { compose, css, Label, BoxStyleProps, useStyleProps, boxStyleProps } from '..';
import { InputFieldProvider } from './input-field-provider';
import type { InputFieldVariants } from './input-field.css';
import * as styles from './input-field.css';

export type InputFieldProps = PropsWithChildren<
  AriaFieldProps &
    BoxStyleProps &
    InputFieldVariants &
    HTMLProps<HTMLSpanElement> & {
      /**
       * Whether show optional label
       */
      isRequired?: boolean;

      /**
       * Right side for label
       */
      labelRightSide?: ReactNode;

      /**
       * Position of label
       */
      position?: 'top' | 'side';

      /**
       * Whether show tooltip
       */
      tooltip?: React.ReactNode;
    }
>;

/**
 * @name
 * InputField
 *
 * @description
 * Non semantic UI text element that inherits styling from it's
 * parent container.
 *
 * @example
 * <InputField label="Input">
 *   <TextField/>
 * </InputField>
 */
export const InputField = (props: InputFieldProps) => {
  const { children, label, isRequired = true, labelRightSide, description, tooltip, position = 'top' } = props; // Pick HTML color for typing
  const ref = useRef<HTMLDivElement>(null);

  const { labelProps, fieldProps, descriptionProps } = useField(props);
  const { styleProps } = useStyleProps(props, boxStyleProps);

  // Bypass conflict type of 'color' attribute
  const newLabelProps: Omit<typeof labelProps, 'color'> = labelProps;

  return (
    <div
      ref={ref}
      className={compose(css(styles.inputField), styles.inputFieldVariants({ position }), css(styleProps))}
    >
      <Label
        {...newLabelProps}
        tooltip={tooltip}
        isRequired={isRequired}
        labelRightSide={labelRightSide}
        description={description}
        descriptionProps={descriptionProps}
        marginBottom={position === 'top' ? '$space125' : ''}
      >
        {label}
      </Label>
      <InputFieldProvider fieldProps={fieldProps}>{children}</InputFieldProvider>
    </div>
  );
};
