import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Icon, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { useDispatch, useSelector } from 'react-redux';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { Box, Button, Checkbox, Link, Text } from 'registration-flow-v2';
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';

import { STRIPE_CONFIG } from 'variables/app-config';

type SubscriptionDetailsProperties = {
  pendingPrimaryOwner: any;
  companyName: string;
  workPhone: string;
  abn: string;
  ndisNumber: string;
  companySize: string;
  onValidStateChange(isValid: boolean): void;
  setIsSubmitting(isSubmitting: boolean): void;
  onCreateOrganisationSuccess: (data: { token: string; serviceProviderId: string }) => void;
};

export function SubscriptionDetails(props: SubscriptionDetailsProperties) {
  const stripe = useStripe();
  const elements = useElements();
  const trialPeriodInDays = STRIPE_CONFIG.DEFAULT_TRIAL_PERIOD_IN_DAYS;

  // STATE SELECTORS
  const stripeStore = useSelector((state: IRootState) => state.stripeStore);

  // DISPATCH ACTIONS
  const { stripeStore: stripeDispatch, authStore: authDispatch } = useDispatch<IRootDispatch>();

  const { doCreateSubscription, doUpdateCustomer, doGetSubscriptionList } = stripeDispatch;
  const { doCreateServiceProviderInstance } = authDispatch;

  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [isPaymentElementComplete, setIsPaymentElementComplete] = useState<boolean>(false);
  const [paymentMethodError, setPaymentMethodError] = useState<boolean>(false);
  const [isLoadingPaymentElement, setIsLoadingPaymentElement] = useState<boolean>(true);
  const [isValidatingStripe, setIsValidatingStripe] = useState<boolean>(false);

  const onPaymentElementChange = (e) => {
    setIsPaymentElementComplete(e.complete);
    setPaymentMethodError(false);
  };

  const onCreateOrganisation = async () => {
    const {
      pendingPrimaryOwner,
      companyName,
      workPhone,
      abn,
      ndisNumber,
      companySize,
      onCreateOrganisationSuccess,
      setIsSubmitting,
    } = props;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setPaymentMethodError(false);
    try {
      setIsValidatingStripe(true);
      //confirm card setup, here we validate the card given by user and confirm its integrity
      const confirm = await stripe.confirmSetup({ elements, redirect: 'if_required' });

      if (confirm.error) {
        setPaymentMethodError(true);
        throw Error(confirm.error.code);
      }

      setIsSubmitting(true);

      //Get existing subscription if any
      const existingSubscriptions: any = await doGetSubscriptionList({
        customer: pendingPrimaryOwner.stripeCustomerId,
        limit: 1,
      });

      if (existingSubscriptions?.data?.length > 0) {
        throw new Error('You already have an active subscription for this account');
      }

      //create subscription for user
      const subscription: any = await doCreateSubscription({
        data: {
          customer: pendingPrimaryOwner.stripeCustomerId,
          items: [{ price: STRIPE_CONFIG.STRIPE_PRODUCT_ID }],
          default_payment_method: confirm.setupIntent.payment_method,
          default_tax_rates: STRIPE_CONFIG.DEFAULT_TAX_RATES,
        },
      });

      //update stripe customer name to use company name
      await doUpdateCustomer({ stripeCustomerId: pendingPrimaryOwner.stripeCustomerId, data: { name: companyName } });

      const instanceData = {
        serviceProviderName: companyName,
        serviceProviderPhone: workPhone,
        email: pendingPrimaryOwner.email,
        timezone: 'Australia/Melbourne',
        abn: abn,
        uid: pendingPrimaryOwner.firebaseUid,
        stripeCustomerId: pendingPrimaryOwner.stripeCustomerId,
        stripeSubscriptionId: subscription.id,
        ndisNumber: ndisNumber,
        companySize: companySize,
      };

      //create Service provider instance
      const result = await doCreateServiceProviderInstance(instanceData);

      if (result) {
        //call prop function
        onCreateOrganisationSuccess(result);
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: e.message || 'Oops! Something went wrong.',
      });
      setIsSubmitting(false);
    } finally {
      setIsValidatingStripe(false);
    }
  };

  useEffect(
    function syncProgressBarStatus() {
      props.onValidStateChange(isPaymentElementComplete && isAgreed);
    },
    [isPaymentElementComplete, isAgreed],
  );

  return (
    <>
      <section id="user-form">
        {isLoadingPaymentElement && <SpinningLoader size={50} message="" />}
        <PaymentElement
          options={{ terms: { card: 'never' } }}
          onChange={onPaymentElementChange}
          onLoadError={(e) => notification.error({ message: 'Oops! Something went wrong.', description: e.error.type })}
          onReady={() => setIsLoadingPaymentElement(false)}
        />

        {paymentMethodError && (
          <div
            style={{ backgroundColor: '#FFF4F4', margin: 'auto', width: '90%' }}
            className="mt-large bordered border-red-lighter line-height-135 p-medium flex-row rounded"
          >
            <Icon type="close-circle" theme="filled" className="mr-small pt-x-small text-color-red" />
            <div className="flex-column">
              <div className="mb-medium">
                <Text>Invalid Payment Method</Text>
              </div>
              <div>
                <Text>
                  Sorry, your card could not be validated. Please try using another card or reach out to your bank to
                  find out why.
                </Text>
              </div>
            </div>
          </div>
        )}

        <Box marginTop="$space300" marginBottom="$space300">
          <Text color="$default">
            You won’t be charged until after your 30-day trial. Cancel anytime for free before&nbsp;
            {moment().add(trialPeriodInDays, 'days').format('DD MMMM YYYY')}.
          </Text>
        </Box>

        <Box display="flex" marginBottom="$space400">
          <Checkbox onChange={(changeTo) => setIsAgreed(changeTo)} marginTop="$space30">
            <Text color="$default">
              I agree to the GoodHuman for business&nbsp;
              <Link underline size="small" kind="black" href="https://goodhuman.me/terms-of-use/">
                Terms of Use
              </Link>
              &nbsp;and confirm that I have the necessary authority to accept these terms on behalf of&nbsp;
              <Text emphasis="bold">{props.companyName}</Text>.
            </Text>
          </Checkbox>
        </Box>
      </section>

      <Button
        emphasis="filled"
        marginTop="auto"
        size="large"
        width="100%"
        onClick={onCreateOrganisation}
        isLoading={isValidatingStripe}
        isDisabled={!isPaymentElementComplete || !isAgreed}
      >
        {isMobileOnly && 'Create workspace'}
        {isTablet && 'Create account'}
        {isDesktop && 'Sign up'}
      </Button>
    </>
  );
}
