import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { AriaTextFieldProps, useTextField } from '@react-aria/textfield';
import { filterDOMProps, mergeProps } from '@react-aria/utils';
import React, { forwardRef, PropsWithChildren, RefObject, useContext, useRef } from 'react';
import { IValidations } from '../types';
import { compose, css, CSS, useStyleProps, InputStylesProps, inputStyleProps } from '..';
import { InputFieldContext } from '../input-field/input-field-provider';
import { InputGroupContext } from '../input-group/input-group-providers';
import { useMultiValidation } from '../utils';
import * as styles from './text-field.css';

export type TextFieldProps = PropsWithChildren<
  AriaTextFieldProps &
    InputStylesProps & {
      /**
       * Render rules of field (status, rule)
       */
      validations?: IValidations;

      /**
       * Show prefix
       */
      prefix?: React.ReactNode;

      /**
       * Show suffix
       */
      suffix?: React.ReactNode;

      /**
       * Whether show text count
       */
      showCount?: boolean;

      /**
       * Container styles
       */
      containerStyles?: CSS;
    }
>;

/**
 * @name
 * Text Field
 *
 * @description
 * <TextField> is HTML Input Element, allows users enter value with common input props.
 *
 * @example
 * <TextField
 *   placeholder="Enter first name"
 *   label="First Name"
 *   name="firstName"
 *   onChange={(value) => console.log(value)}
 * />
 */

// eslint-disable-next-line react/display-name
const TextField = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  const inputRef = useRef(null);
  const inputGroupProps = useContext(InputGroupContext);
  const {
    value = '',
    prefix,
    suffix,
    label = 'Input',
    showCount,
    containerStyles,
    maxLength = 100,
    validations,
  } = props;

  const { fieldProps } = useContext(InputFieldContext);

  let { inputProps } = useTextField({ ...props, label, inputElementType: 'input' }, inputRef);
  if (inputGroupProps?.isDisabled) inputProps.disabled = true;

  const { focusProps, isFocusVisible, isFocused } = useFocusRing(props);
  const { hoverProps, isHovered } = useHover({ isDisabled: inputProps.disabled });
  const { styleProps, ...otherProps } = useStyleProps(props, inputStyleProps);
  inputProps = mergeProps(inputProps, focusProps, fieldProps);

  const { validationFields, isInvalid } = useMultiValidation({ validations });
  return (
    <div className={css(styles.container, containerStyles)()}>
      <div
        ref={ref}
        {...filterDOMProps(otherProps)}
        {...hoverProps}
        className={compose(
          css(styles.wrapper),
          styles.wrapperVariants({
            isHovered,
            isFocused: isFocused || isFocusVisible,
            isErrored: isInvalid,
            isDisabled: inputProps.disabled,
          }),
          css(styleProps),
          css(inputGroupProps),
        )}
        onClick={() => {
          inputRef.current.focus();
        }}
      >
        {prefix && <div className={css(styles.prefixComponent)()}>{prefix}</div>}
        <input
          ref={inputRef as RefObject<HTMLInputElement>}
          {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
          className={compose(css(styles.reset, styles.textField))}
        />
        {suffix && <div className={css(styles.suffixComponent)()}>{suffix}</div>}
      </div>
      {!inputGroupProps.isInsideGroupInput && (
        <>
          {showCount && (
            <div className={css(styles.charactersTotal)()}>
              <span>
                {value.length}/{maxLength}
              </span>
            </div>
          )}
          {validationFields()}
        </>
      )}
    </div>
  );
});

export default TextField;
