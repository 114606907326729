import { useButton } from '@react-aria/button';
import { useFocus, useHover } from '@react-aria/interactions';
import { HiddenSelect, useSelect } from '@react-aria/select';
import { mergeProps } from '@react-aria/utils';
import { Item } from '@react-stately/collections';
import { useSelectState } from '@react-stately/select';
import { AriaSelectProps } from '@react-types/select';
import { ItemProps } from '@react-types/shared';
import React, { useContext, useState } from 'react';
export { Item } from '@react-stately/collections';

import { Popover } from '../popover';
import * as styles from './select.css';
import type { WrapperVariants } from './select.css';
import { ChevronDown } from '../icon/icons/chevron';
import { InputGroupContext } from '../input-group/input-group-providers';
import { compose, css, CSS, inputStyleProps, InputStylesProps, ListBox, useStyleProps } from '..';
import type { IValidations } from 'registration-flow-v2/types';
import { useMultiValidation } from 'registration-flow-v2/utils';

export type SelectProps = AriaSelectProps<object> &
  WrapperVariants &
  InputStylesProps & {
    /**
     * Distance between button and dropdown content
     */
    offset?: number;

    /**
     * Whether show only prefix of options list
     * NOTE: prefix must be provided in Item
     */
    isOnlyShowPrefix?: boolean;

    /**
     * Render rules of field (status, rule)
     */
    validations?: IValidations;

    /**
     * Container styles
     */
    containerStyles?: CSS;

    /**
     * Trigger element styles
     */
    triggerStyles?: CSS;
  };

/**
 *
 * @name
 * Select
 *
 * @props
 * selectedKey: selected value ( must be string )
 *
 * onSelectionChange(): handle selection change
 *
 * @description
 * The element represents a control that provides a menu of options.
 * This component must use with Select.Item
 *
 * @example
 * <Select items={options}>
 *   {(item: typeof options[number]) => <Select.Item key={item.id}>{item.name}</Select.Item>}
 * </Select>
 *
 * Or
 * <Select >
 *    {options.map((item)=>(<Select.Item key={item.id}><p>{item.name}<p></Select.Item>))}
 * </Select>
 *
 * Render with only prefix in `TextField`
 * <TextField
 *    name="Input"
 *    label="First Name"
 *    placeholder="Enter your first name"
 *    prefixComponent={
 *       <Select isOnlyShowPrefix items={options} selectedKey=>
 *        {(item: typeof options[number]) => (
 *          <Select.Item key={item.id} prefix={<image src={item.logoSrc} />}>
 *            {item.name}
 *          </Select.Item>
 *        )}
 *      </Select>
 *    }
 *  />
 */
export function Select(props: SelectProps) {
  const { isInsideGroupInput = false, isDisabled, ...inputGroupProps } = useContext(InputGroupContext);

  const {
    placeholder = 'Select',
    offset = 5,
    isOnlyShowPrefix = false,
    label = 'Select',
    name,
    validationState,
    containerStyles,
    triggerStyles,
    validations,
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const ref = React.useRef(null);
  const state = useSelectState(props);
  const { triggerProps, valueProps, menuProps } = useSelect({ ...props, label }, state, ref);
  const { selectedItem, close } = state;
  let { isOpen } = state;

  const { styleProps } = useStyleProps(props, inputStyleProps);
  const { buttonProps } = useButton(triggerProps, ref);
  const { focusProps } = useFocus({
    onFocusChange(isFocused) {
      setIsFocus(isFocused);
    },
  });
  const { hoverProps, isHovered } = useHover({});

  const selectProps = mergeProps(buttonProps, focusProps, hoverProps);
  const iconSize = isInsideGroupInput ? '$size200 !important' : '$size275';
  const isSelected = !!selectedItem;

  const isShowOutline = isHovered || isFocus || isOpen || validationState === 'invalid';
  const { validationFields, isInvalid } = useMultiValidation({ validations });
  if (isDisabled) {
    selectProps.disabled = true;
    isOpen = false;
  }
  return (
    <div className={css(containerStyles)()} style={{ zIndex: Number(isShowOutline) }}>
      <div
        className={compose(
          css(styles.wrapper),
          styles.wrapperVariants({
            isFocused: !isInvalid && (isFocus || isOpen),
            isHovered: !isInvalid && isHovered,
            isDisabled,
            isErrored: validationState === 'invalid' || isInvalid,
          }),
          css(styleProps),
          css(inputGroupProps as CSS),
        )}
      >
        <HiddenSelect state={state} triggerRef={ref} label={label} name={name} />
        <button
          {...selectProps}
          disabled
          ref={ref}
          className={compose(
            css(styles.triggerElementReset, styles.triggerElement),
            styles.triggerElementVariants({
              isInsideGroupInput,
              isNonSelected: !isSelected,
              isDisabled,
            }),
            css(triggerStyles),
          )}
        >
          <span {...valueProps} className={css(styles.selectedValue)()}>
            {isSelected ? (
              <>
                {selectedItem.props.prefix} {isOnlyShowPrefix ? '' : selectedItem.rendered}
              </>
            ) : (
              placeholder
            )}
          </span>
          <div className={css(styles.selectIcon)()} style={{ transform: isOpen ? `rotate(180deg)` : '' }}>
            <ChevronDown width={iconSize} height={iconSize} color="$black" />
          </div>
        </button>
        {isOpen && (
          <Popover {...styles.overlayStyles} marginTop={offset} isOpen={isOpen} onClose={close}>
            <ListBox {...menuProps} state={state} />
          </Popover>
        )}
      </div>
      {!isInsideGroupInput && validationFields()}
    </div>
  );
}

type SelectItem = ItemProps<unknown> & {
  /**
   * Indicator or shorthand append in front of option
   */
  prefix?: React.ReactNode;
};

/**
 * @props
 * key: unique value which is passed as args to `onSelectionChange()`
 *
 * prefix: Indicator or shorthand append in front of option
 */
Select.Item = Item as React.FunctionComponent<SelectItem>;
